<template>
  <v-card style="height: 282px">
    <v-card-text class="black--text pt-2">
      <v-row style="align-items: center" no-gutters>
        <v-col>
          <span class="text-h6 font-weight-regular">
            {{ selectedStation.rastless_product.toUpperCase() }} -
            {{ $t("baseline.monthlyValues") }}</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" v-if="!loading">
          <year-filter
            :years="years"
            @filteredYears="changeYears"
          ></year-filter>
        </v-col>
      </v-row>
    </v-card-text>
    <div
      ref="plot"
      id="monthly-values-plot"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '70%'
      }"
    ></div>
  </v-card>
</template>

<script>
import YearFilter from "@/core/components/baseline/YearFilter.vue";
import { mapState } from "vuex";
import baselineMixin from "@/core/mixins/baseline.mixin";
import plotly from "plotly.js-dist";

export default {
  name: "MonthlyValues",
  components: { YearFilter },
  mixins: [baselineMixin],
  props: {
    showThresholds: { type: Boolean, default: () => false },
    thresholds: { type: Object },
    years: { type: Array },
    region: null,
    reload: { type: Boolean }
  },
  data: () => ({
    selectedStartYear: null,
    selectedEndYear: null,
    data: null,
    boxPlotData: [],
    loading: true
  }),
  computed: {
    ...mapState("baseline", ["selectedStation", "monthsDict"]),
    colorBlueArray() {
      return [
        "#1D4180",
        "#134FA9",
        "#276DBB",
        "#3582C6",
        "#4194CF",
        "#54AEDF",
        "#40ADD5"
      ];
    }
  },

  methods: {
    updatePLot(data, layout) {
      plotly.react("monthly-values-plot", data, layout);
    },
    changeYears(filteredyears) {
      this.createBoxPlotData(filteredyears, this.data);
      plotly.newPlot(
        "monthly-values-plot",
        this.boxPlotData,
        this.layout,
        this.configs
      );
    },
    createBoxPlotData(years, data) {
      this.boxPlotData = [];
      years.forEach((year, index) => {
        const x = [];
        const y = [];
        this.monthsDict.forEach(month => {
          if (data.datetimes.includes(`${year}-${month.val}`)) {
            const index = data.datetimes.indexOf(`${year}-${month.val}`);
            x.push(`${this.$t(`${month.label}`)}`);
            y.push(data.values[index]);
          } else {
            x.push(`${this.$t(`${month.label}`)}`);
            y.push(undefined);
          }
        });
        this.boxPlotData.push({
          name: `${year}`,
          y: y,
          x: x,
          type: "bar",
          marker: { color: this.colorBlueArray[index] }
        });
      });
      this.configs = this.getPLotConfigs();
      this.layout = this.getBarLayout(this.selectedStation.rastless_unit);
    }
  },
  watch: {
    async reload() {
      this.loading = true;
      this.data = await this.fetchStatisticsMeanWithinTimeRange(
        this.region,
        this.selectedStation.id,
        "monthly",
        "mean",
        `${this.selectedStartYear}-01-01`,
        `${this.selectedEndYear}-12-31`
      );
      this.createBoxPlotData(this.years, this.data);
      plotly.newPlot(
        "monthly-values-plot",
        this.boxPlotData,
        this.layout,
        this.configs
      );
      this.loading = false;
    },
    async selectedStation() {
      this.loading = true;
      this.selectedStartYear = this.years[0];
      this.selectedEndYear = this.years[this.years.length - 1];
      this.data = await this.fetchStatisticsMeanWithinTimeRange(
        this.region,
        this.selectedStation.id,
        "monthly",
        "mean",
        `${this.selectedStartYear}-01-01`,
        `${this.selectedEndYear}-12-31`
      );
      this.createBoxPlotData(this.years, this.data);
      plotly.newPlot(
        "monthly-values-plot",
        this.boxPlotData,
        this.layout,
        this.configs
      );
      this.loading = false;
    },
    showThresholds() {
      if (this.showThresholds) {
        if (this.thresholds.local_thresholds !== null) {
          this.layout = this.addThresholdLayout(
            this.layout,
            this.thresholds.local_thresholds.threshold_alert,
            this.thresholds.local_thresholds.threshold_warning
          );
        } else {
          this.layout = this.addThresholdLayout(
            this.layout,
            this.thresholds.global_thresholds.threshold_alert,
            this.thresholds.global_thresholds.threshold_warning
          );
        }
        this.updatePLot(this.boxPlotData, this.layout);
      } else {
        this.layout = this.getBarLayout(this.selectedStation.rastless_unit);
        this.updatePLot(this.boxPlotData, this.layout);
      }
    }
  },
  async created() {
    this.selectedStartYear = this.years[0];
    this.selectedEndYear = this.years[this.years.length - 1];
    this.data = await this.fetchStatisticsMeanWithinTimeRange(
      this.region,
      this.selectedStation.id,
      "monthly",
      "mean",
      `${this.selectedStartYear}-01-01`,
      `${this.selectedEndYear}-12-31`
    );
    this.createBoxPlotData(this.years, this.data);
    plotly.newPlot(
      "monthly-values-plot",
      this.boxPlotData,
      this.layout,
      this.configs
    );
    this.loading = false;
  }
};
</script>

<style scoped></style>
