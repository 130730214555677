<template>
  <div class="child" style="height: 400px" v-if="layerTimesteps">
    <div
      :id="container"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%'
      }"
      class="child"
    >
      <v-select
        class="layer-selector"
        v-model="selectedMonth"
        :items="months"
        label="Select Month"
        item-value="value"
        item-text="label"
        dense
        single-line
        flat
        solo
      ></v-select>
      <v-img :src="legendImage" class="legend-image" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import MapboxConfig from "@/core/plugins/mapbox";
import bbox from "@turf/bbox";
import mapboxgl from "mapbox-gl";
import baselineMixin from "@/core/mixins/baseline.mixin";

export default {
  name: "LayerMap",
  props: {
    selectedProduct: {
      type: Object,
      default: () => {}
    },
    container: null
  },
  mixins: [baselineMixin],
  data: () => ({
    map: null,
    flying: false,
    height: null,
    selectedMonth: null,
    months: [],
    rasterToken: null,
    layerTimesteps: [],
    downloadPossible: true,
    month_dict: [
      { val: "01", label: "month.january" },
      { val: "02", label: "month.february" },
      { val: "03", label: "month.march" },
      { val: "04", label: "month.april" },
      { val: "05", label: "month.may" },
      { val: "06", label: "month.june" },
      { val: "07", label: "month.july" },
      { val: "08", label: "month.august" },
      { val: "09", label: "month.september" },
      { val: "10", label: "month.october" },
      { val: "11", label: "month.november" },
      { val: "12", label: "month.december" }
    ],
    legendImage: null,
    indexLayerDummys: [
      {
        id: "z-index-1",
        type: "symbol",
        source: "z-index-1"
      },
      {
        id: "z-index-2",
        type: "symbol",
        source: "z-index-2"
      }
    ]
  }),
  computed: {
    ...mapState("dashboard", ["center"]),
    ...mapGetters("dashboard", ["getCenter"]),
    ...mapState("baseline", ["selectedStation", "selectedStations"]),
    ...mapState("management", ["accessToken"])
  },
  methods: {
    ...mapActions("dashboard", ["changeCenter"]),
    ...mapActions("management", ["getOrFetchAccessToken"]),
    defineExistingMonths() {
      let monthSet = new Set();
      this.layerTimesteps.forEach(step => {
        monthSet.add(
          `${step.datetime.split("-")[0]}-${step.datetime.split("-")[1]}`
        );
      });
      Array.from(monthSet).forEach(month => {
        const monthNumber = month.split("-")[1];
        const yearNumber = month.split("-")[0];
        const monthDict = this.month_dict.filter(
          dictEntry => dictEntry.val === monthNumber
        );
        if (this.container.includes("compare-left")) {
          this.months.push({
            label: `${this.selectedStations[0].station_name}: ${this.$t(
              `${monthDict[0].label}`
            )} ${yearNumber}`,
            value: month
          });
        } else if (this.container.includes("compare-right")) {
          this.months.push({
            label: `${this.selectedStations[1].station_name}: ${this.$t(
              `${monthDict[0].label}`
            )} ${yearNumber}`,
            value: month
          });
        } else {
          this.months.push({
            label: `${this.$t(`${monthDict[0].label}`)} ${yearNumber}`,
            value: month
          });
        }
      });
      if (this.container == "left") {
        this.selectedMonth = this.months[0].value;
      } else {
        this.selectedMonth = this.months[1].value;
      }
    },

    async setLegendImage(cmapName) {
      const response = await this.$rastless.get(`/cmaps/${cmapName}`);
      const image = response.data.legendImage;
      if (image) {
        this.legendImage = `data:image/png;base64,${image}`;
      }
    },
    createMap() {
      const bounds = bbox(this.selectedProduct.geometry);

      mapboxgl.accessToken = MapboxConfig.accessToken;
      const map = new mapboxgl.Map({
        container: this.container,
        style: "mapbox://styles/mapbox/streets-v11",
        bounds: bounds
      });

      map.dragRotate.disable();
      map.on("load", () => {
        map.fitBounds(bounds, { padding: 50 });
        this.addIndexLayerDummys();
        this.addRastlessLayer();
        this.addBaselineStationLayer();
      });
      const viewer = () => {
        const { lng, lat } = map.getCenter();
        const zoom = map.getZoom();
        this.changeCenter({
          lat,
          lng,
          active: true,
          zoom
        });
      };
      map.on("dragend", () => {
        viewer();
      });
      map.on("zoomend", () => {
        viewer();
      });
      this.map = map;
    },

    addIndexLayerDummys() {
      const map = this.map;
      this.indexLayerDummys.forEach(layerDummy => {
        if (map.getLayer(layerDummy.id)) {
          map.removeLayer(layerDummy.id);
        }
        if (map.getSource(layerDummy.id)) {
          map.removeSource(layerDummy.id);
        }
        map.addSource(layerDummy.id, {
          type: "geojson",
          data: { type: "FeatureCollection", features: [] }
        });
        map.addLayer(layerDummy);
      });
    },

    addBaselineStationLayer() {
      const map = this.map;

      if (map.getLayer("geojson-source")) {
        map.removeLayer("geojson-source");
      }
      if (map.getSource("geojson-source")) {
        map.removeSource("geojson-source");
      }

      map.addSource("geojson-source", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: this.selectedProduct.geometry
        }
      });
      map.addLayer(
        {
          id: "geojson-Point",
          source: "geojson-source",
          type: "circle",
          layout: {
            visibility: `visible`
          },
          paint: {
            "circle-color": "#ff9800",
            "circle-opacity": 1,
            "circle-radius": 20
          },
          filter: ["==", "$type", "Point"]
        },
        "z-index-2"
      );
      map.addLayer(
        {
          id: "geojson-Polygon",
          source: "geojson-source",
          type: "line",
          layout: {
            visibility: `visible`
          },
          paint: {
            "line-color": "#ff9800",
            "line-width": 3
          },
          filter: ["!=", "$type", "Point"]
        },
        "z-index-2"
      );
    },
    addRastlessLayer() {
      const map = this.map;
      if (map.getLayer("raster-layer")) {
        map.removeLayer("raster-layer");
      }
      if (map.getSource("raster-source")) {
        map.removeSource("raster-source");
      }

      const domain = process.env.VUE_APP_RASTLESS_URL;
      let urlTiles = "";

      if (this.selectedProduct.product === "satellite") {
        urlTiles = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MapboxConfig.accessToken}`;
      } else {
        urlTiles = `${domain}/aggregate/${this.selectedProduct.rastless_layer_id}/tile/{z}/{x}/{y}.png?date_startswith=${this.selectedMonth}&token=${this.accessToken}`;
      }
      map.addSource(`raster-source`, {
        type: "raster",
        tiles: [urlTiles],
        tileSize: 256
      });
      map.addLayer(
        {
          id: `raster-layer`,
          type: `raster`,
          source: `raster-source`
        },
        "z-index-1"
      );
    }
  },
  watch: {
    center(param) {
      const { lat, lng, active, zoom } = param;
      if (active) {
        this.map.setCenter([lng, lat]);
        this.map.setZoom(zoom);
      }
    },
    selectedMonth() {
      this.addRastlessLayer();
    },
    selectedStation() {
      this.fetchTimesteps(this.selectedProduct, this.accessToken).then(
        response => {
          this.layerTimesteps = response;
          this.setLegendImage(this.selectedProduct.rastless_color_map);
          this.defineExistingMonths();
          this.createMap();
        }
      );
    },
    selectedStations() {
      this.fetchTimesteps(this.selectedProduct, this.accessToken).then(
        response => {
          this.layerTimesteps = response;
          this.setLegendImage(this.selectedProduct.rastless_color_map);
          this.defineExistingMonths();
          this.createMap();
        }
      );
    }
  },
  async created() {
    this.fetchTimesteps(this.selectedProduct, this.accessToken).then(
      response => {
        this.layerTimesteps = response;
        this.setLegendImage(this.selectedProduct.rastless_color_map);
        this.defineExistingMonths();
      }
    );
  },
  mounted() {
    this.createMap();
  }
};
</script>

<style scoped>
.child {
  height: 100%;
}

.layer-selector {
  z-index: 2;
  padding-top: 12px;
  padding-left: 16px;
  width: 220px;
}

.v-text-field.v-text-field--solo.v-input--dense >>> .v-input__control {
  /*/ reduce heigth from product drop down in map */
  min-height: 26px;
}

.legend-image {
  top: 270px;
  right: 20px;
  width: 210px;
  background-color: rgba(250, 250, 250, 0.5);
  z-index: 3;
}
</style>
