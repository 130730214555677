<template>
  <v-card style="height: 282px">
    <v-card-text class="black--text pt-2">
      <v-row style="align-items: center">
        <v-col>
          <span class="text-h6 font-weight-regular">
            {{ selectedStation.rastless_product.toUpperCase() }} -
            {{ $t("baseline.statistics") }}</span
          >
        </v-col>
      </v-row>
      <v-row v-if="station">
        <v-col cols="6" class="px-16" v-if="station.overall_statistics">
          <v-row class="mb-5">
            <v-col
              >{{ $t("baseline.min") }}
              <p
                :style="{
                  color: getStatusColor(
                    station.overall_statistics.min,
                    thresholds
                  )
                }"
                class="my-0  text-h2"
              >
                {{ station.overall_statistics.min.toFixed(2) }}
              </p></v-col
            >
            <v-divider class="mt-4" vertical />
            <v-col
              >{{ $t("baseline.max") }}
              <p
                :style="{
                  color: getStatusColor(
                    station.overall_statistics.max,
                    thresholds
                  )
                }"
                class="my-0 text-h2"
              >
                {{ station.overall_statistics.max.toFixed(2) }}
              </p></v-col
            >
            <v-divider class="mt-4" vertical />
            <v-col
              >{{ $t("baseline.median") }}
              <p
                :style="{
                  color: getStatusColor(
                    station.overall_statistics.median,
                    thresholds
                  )
                }"
                class="my-0 text-h2"
              >
                {{ station.overall_statistics.median.toFixed(2) }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              >{{ $t("baseline.standardDeviation") }}
              <p class="my-0 text-h4">
                {{ station.overall_statistics.stddev.toFixed(2) }}
              </p></v-col
            >
            <v-divider class="mt-4" vertical />
            <v-col
              >{{ $t("baseline.numberOfAlerts") }}
              <p class="my-0 text-h4">
                {{ alertCount }}
              </p></v-col
            >
            <v-divider class="mt-4" vertical />
            <v-col>
              {{ $t("baseline.numberOfScenes") }}
              <p class="my-0 text-h4">
                {{ station.overall_statistics.nb_scenes.toFixed(0) }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <div style="background-color: #E9EFF5">
            <v-row>
              <v-col>
                <p align="center" class="text-h6 font-weight-light">
                  {{ $t("baseline.5HighestValues") }}
                </p>
                <v-row>
                  <v-col align="center" class="font-weight-bold py-2">
                    {{ $t("baseline.date") }}
                  </v-col>
                  <v-col align="center" class="font-weight-bold py-2">
                    {{ $t("baseline.value") }} [{{ station.rastless_unit }}]
                  </v-col>
                </v-row>
                <v-row v-for="date in maxData" :key="date.date">
                  <v-col align="center" class="py-2">{{ date.date }}</v-col>
                  <v-col align="center" class="py-2">{{ date.value }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <p align="center" class="text-h6 font-weight-light">
                  {{ $t("baseline.5LowestValues") }}
                </p>
                <v-row>
                  <v-col align="center" class="font-weight-bold py-2">
                    {{ $t("baseline.date") }}
                  </v-col>
                  <v-col align="center" class="font-weight-bold py-2">
                    {{ $t("baseline.value") }} [{{ station.rastless_unit }}]
                  </v-col>
                </v-row>
                <v-row v-for="date in minData" :key="date.date">
                  <v-col align="center" class="py-2">{{ date.date }}</v-col>
                  <v-col align="center" class="py-2">{{ date.value }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baselineMixin from "@/core/mixins/baseline.mixin";
import { mapState } from "vuex";

export default {
  name: "Statistics",
  props: {
    thresholds: { type: Object },
    years: { type: Array },
    region: null,
    virtualStation: { type: Object },
    reload: { type: Boolean }
  },
  data: () => ({
    minData: null,
    maxData: null,
    alertCount: 0,
    station: {}
  }),
  mixins: [baselineMixin],
  computed: {
    ...mapState("baseline", ["selectedStation", "color1", "color2"])
  },
  methods: {
    getHighestAndLowestValues() {
      const Arrdata = [];
      for (const [key, value] of Object.entries(this.data)) {
        if (value.length > 1) {
          value.forEach(value => {
            Arrdata.push({ date: key, value: value });
          });
        } else {
          Arrdata.push({ date: key, value: value[0] });
        }
      }
      let sortArr = Arrdata.sort(function(a, b) {
        return a.value - b.value;
      });
      this.maxData = sortArr.slice(Math.max(sortArr.length - 5, 0)).reverse();
      this.minData = sortArr.slice(0, 5);
      return Arrdata;
    },
    countAlerts(data) {
      if (this.thresholds.local_thresholds !== null) {
        data.forEach(step => {
          if (step.value >= this.thresholds.local_thresholds.threshold_alert) {
            this.alertCount += 1;
          }
        });
      } else if (this.thresholds.global_thresholds?.threshold_alert) {
        data.forEach(step => {
          if (step.value >= this.thresholds.global_thresholds.threshold_alert) {
            this.alertCount += 1;
          }
        });
      }
    }
  },
  watch: {
    async reload() {
      this.station = null;
      await this.fetchSingleStationStats(
        this.region,
        this.selectedStation.rastless_layer_id,
        this.virtualStation.id
      ).then(response => {
        this.station = response[0];
      });
      this.data = await this.fetchStatisticsWithinTimeRange(
        this.region,
        this.selectedStation.id,
        "daily",
        `${this.years[0]}-01-01`,
        `${this.years[this.years.length - 1]}-12-31`
      );
      const arrData = this.getHighestAndLowestValues();
      this.countAlerts(arrData);
    },
    async selectedStation() {
      this.station = null;
      await this.fetchSingleStationStats(
        this.region,
        this.selectedStation.rastless_layer_id,
        this.virtualStation.id
      ).then(response => {
        this.station = response[0];
      });
      this.data = await this.fetchStatisticsWithinTimeRange(
        this.region,
        this.selectedStation.id,
        "daily",
        `${this.years[0]}-01-01`,
        `${this.years[this.years.length - 1]}-12-31`
      );
      const arrData = this.getHighestAndLowestValues();
      this.countAlerts(arrData);
    }
  },
  async created() {
    this.fetchSingleStationStats(
      this.region,
      this.selectedStation.rastless_layer_id,
      this.virtualStation.id
    ).then(response => {
      this.station = response[0];
    });
    this.data = await this.fetchStatisticsWithinTimeRange(
      this.region,
      this.selectedStation.id,
      "daily",
      `${this.years[0]}-01-01`,
      `${this.years[this.years.length - 1]}-12-31`
    );
    const arrData = this.getHighestAndLowestValues();
    this.countAlerts(arrData);
  }
};
</script>

<style scoped></style>
