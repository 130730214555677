<template>
  <v-main
    fluid
    style=" color: #112740"
    class="px-5 mt-5 d-flex flex-column flex-grow-1 fill-height overflow-y-auto overflow-x-hidden"
  >
    <v-row v-if="virtualStation">
      <v-col cols="auto">
        <div>
          <v-btn class="pb-1" icon color="#173466" @click="$router.go(-1)">
            <v-icon large>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="text-h5">
            {{ virtualStation.name }}
          </span>
          <span class="text-h9 pl-3"> {{ area }} km<sup>2</sup> . </span>
          <span v-if="dateRange" class="text-h9 pl-3">
            {{ dateRange }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="selectedStation">
      <v-col cols="3" style="elevation: 4turn">
        <v-select
          dense
          hide-details
          v-model="selectedStation"
          :items="baselineStations"
          :value="selectedStation"
          :item-text="
            item =>
              item.rastless_product.toUpperCase() +
              ' - ' +
              item.rastless_layer_name
          "
          label="Layer"
          solo
          return-object
        ></v-select>
      </v-col>
      <v-col cols="auto">
        <v-switch
          v-model="showThresholds"
          :label="
            showThresholds
              ? $t('baseline.hideThresholds')
              : $t('baseline.showThresholds')
          "
          class="mt-1"
          :disabled="!productThreshold"
        ></v-switch>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="mt-2"
          text
          small
          color="primary"
          :to="{ name: 'GlobalsThresholds' }"
          >{{ $t("baseline.editThresholds") }}
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>
    <div v-if="selectedStation && years.length && !loading">
      <annual-comparison
        class="mt-4"
        :show-thresholds="showThresholds"
        :thresholds="productThreshold"
        :years="years"
        :region="regionId"
        :reload="reload"
      >
      </annual-comparison>
      <monthly-values
        class="mt-6"
        :show-thresholds="showThresholds"
        :thresholds="productThreshold"
        :years="years"
        :region="regionId"
        :reload="reload"
      ></monthly-values>
      <monthly-comparison
        class="mt-6"
        :show-thresholds="showThresholds"
        :thresholds="productThreshold"
        :station="selectedStation"
        :years="years"
        :region="regionId"
        :reload="reload"
      ></monthly-comparison>
      <v-card class="mt-6">
        <v-card-title class="text-h6 font-weight-regular py-2 px-4">
          {{ selectedStation.rastless_product.toUpperCase() }} -
          {{ $t("baseline.monthlyMap") }}
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col class="pr-1">
              <layer-map
                :selected-product="selectedStation"
                :container="'left'"
              ></layer-map>
            </v-col>
            <v-col class="pl-1">
              <layer-map
                :selected-product="selectedStation"
                :container="'right'"
              ></layer-map>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <single-values
        class="mt-6"
        :show-thresholds="showThresholds"
        :thresholds="productThreshold"
        :years="years"
        @reload="reloadBaselineValues"
      />
      <statistics
        class="mt-6 mb-10"
        :thresholds="productThreshold"
        :years="years"
        :region="regionId"
        :virtual-station="virtualStation"
        :reload="reload"
      ></statistics>
    </div>
    <div v-else>
      <v-overlay opacity="0.7" absolute>
        <div class="text-center">
          <v-progress-circular indeterminate size="84"></v-progress-circular>
          <br />
          <br />
          <span> {{ this.$t("baseline.collectingData") }} </span>
        </div>
      </v-overlay>
    </div>
  </v-main>
</template>

<script>
import regionMixin from "@/core/mixins/region.mixin";
import { mapActions, mapState, mapGetters } from "vuex";
import AnnualComparison from "@/core/components/baseline/baslineSingle/AnnualComparison.vue";
import MonthlyValues from "@/core/components/baseline/baslineSingle/MonthlyValues.vue";
import LayerMap from "@/core/components/baseline/baslineSingle/LayerMap.vue";
import SingleValues from "@/core/components/baseline/baslineSingle/SingleValues.vue";
import MonthlyComparison from "@/core/components/baseline/baslineSingle/MonthlyComparison.vue";
import baselineMixin from "@/core/mixins/baseline.mixin";
import geojsonArea from "@mapbox/geojson-area";
import Statistics from "@/core/components/baseline/baslineSingle/Statistics.vue";
import Vue from "vue";

export default {
  name: "BaselineSingleDetails",
  components: {
    Statistics,
    MonthlyComparison,
    SingleValues,
    LayerMap,
    MonthlyValues,
    AnnualComparison
  },
  mixins: [regionMixin, baselineMixin],
  data: () => ({
    area: null,
    virtualStation: null,
    baselineStations: [],
    showThresholds: false,
    regionThresholds: [],
    productThreshold: {},
    dateRange: "",
    years: [],
    loading: true,
    regionId: null,
    reload: false
  }),
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    ...mapActions("baseline", ["updateSelectedStation"]),
    async defineRangeString() {
      const data = await this.fetchStatistics(
        this.$route.params.regionId,
        this.selectedStation.id,
        "monthly"
      );

      const dates = Object.keys(data);
      let firstDate = dates[0];
      let month = this.monthsDict.filter(
        month => month.val == firstDate.split("-")[1]
      );
      firstDate = `${this.$t(`${month[0].label}`)} ${firstDate.split("-")[0]}`;
      let endDate = dates[dates.length - 1];
      month = this.monthsDict.filter(
        month => month.val == endDate.split("-")[1]
      );
      endDate = `${this.$t(`${month[0].label}`)} ${endDate.split("-")[0]}`;
      this.dateRange = `${firstDate} - ${endDate}`;
    },
    calculatePolygonsArea(station) {
      const area = geojsonArea.geometry(station.geometry);
      const orgSumArea = ((area / 1000000) * 1000) / 1000; // square kilometer
      return orgSumArea.toFixed(2);
    },
    defineProductThresholds() {
      const thresholds = this.regionThresholds.filter(
        productThreshold =>
          productThreshold.product === this.selectedStation.rastless_product
      );
      return thresholds[0];
    },
    reloadBaselineValues() {
      if (this.reload) {
        this.reload = false;
      } else {
        this.reload = true;
      }
    }
  },
  computed: {
    ...mapState("baseline", ["selectedStation", "monthsDict", "product"]),
    ...mapGetters("baseline", ["getStation"]),
    hasEomapRole() {
      const hasEomapRole = Vue.prototype.$keycloak.realmAccess?.roles.includes(
        "eomap"
      );
      return hasEomapRole;
    },
    selectedStation: {
      get() {
        return this.getStation;
      },
      set(station) {
        this.updateSelectedStation(station);
      }
    }
  },
  watch: {
    async selectedStation() {
      this.loading = true;
      const yearData = await this.fetchStatistics(
        this.$route.params.regionId,
        this.selectedStation.id,
        "yearly"
      );
      if (!Object.keys(yearData).length) {
        await this.fetchSingleStationStats(
          this.regionId,
          this.selectedStation.rastless_layer_id,
          this.virtualStation.id
        ).then(async () => {
          const yearData = await this.fetchStatistics(
            this.$route.params.regionId,
            this.selectedStation.id,
            "yearly"
          );
          this.years = Object.keys(yearData);
          this.productThreshold = this.defineProductThresholds();
          this.dateRange = this.defineRangeString();
          this.loading = false;
        });
      } else {
        this.years = Object.keys(yearData);
        this.productThreshold = this.defineProductThresholds();
        this.dateRange = this.defineRangeString();
        this.loading = false;
      }
    }
  },
  async created() {
    this.regionId = this.$route.params.regionId;
    this.getOrFetchAccessToken().then(async () => {
      this.virtualStation = await this.fetchVirtualStation(
        this.$route.params.stationId
      );
      this.area = this.calculatePolygonsArea(this.virtualStation);
      this.baselineStations = await this.fetchBaselineStationsForStation(
        this.regionId,
        this.virtualStation.id,
        true
      );
      if (!this.selectedStation?.id) {
        this.updateSelectedStation(this.baselineStations[0]);
      }
      await this.fetchSingleStationStats(
        this.regionId,
        this.selectedStation.rastless_layer_id,
        this.virtualStation.id
      ).then(async () => {
        this.defineRangeString();
        const yearData = await this.fetchStatistics(
          this.regionId,
          this.selectedStation.id,
          "yearly"
        );
        this.years = Object.keys(yearData);
        this.loading = false;
        this.regionThresholds = await this.fetchStationAlerts(
          this.selectedStation.virtual_station_id
        );
        // this.regionThresholds = await this.fetchRegionThresholds(this.regionId);
        this.productThreshold = this.defineProductThresholds();
      });
    });
  }
};
</script>

<style scoped></style>
