<template>
  <v-card style="height: 282px">
    <v-card-text class="black--text pt-2">
      <v-row style="align-items: center" no-gutters>
        <v-col>
          <span class="text-h6 font-weight-regular">
            {{ selectedStation.rastless_product.toUpperCase() }} -
            {{ $t("baseline.annualComparison") }}</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="px-2">
          <span class="font-weight-bold"
            >{{ $t("baseline.referencePeriod") }}:</span
          >
        </v-col>
        <v-col cols="auto" class="px-2">
          <span>{{ $t("baseline.start") }}: </span>
        </v-col>
        <v-col cols="auto" class="px-2">
          <v-select
            style="width: 150px"
            dense
            hide-details
            :items="years"
            v-model="selectedStartYear"
            label="Layer"
            solo
            return-object
          ></v-select>
        </v-col>
        <v-col cols="auto" class="px-2">
          <span>{{ $t("baseline.end") }}: </span>
        </v-col>
        <v-col cols="auto" class="pl-2">
          <v-select
            style="width: 150px"
            dense
            hide-details
            :items="years"
            v-model="selectedEndYear"
            label="Layer"
            solo
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <div
      ref="plot"
      id="annualComparisonPlot"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '70%'
      }"
    ></div>
  </v-card>
</template>

<script>
import baselineMixin from "@/core/mixins/baseline.mixin";
import { mapState } from "vuex";
import plotly from "plotly.js-dist";

export default {
  name: "AnnualComparison",
  props: {
    showThresholds: { type: Boolean, default: () => false },
    thresholds: { type: Object },
    region: null,
    years: { type: Array },
    reload: { type: Boolean }
  },
  mixins: [baselineMixin],
  data: () => ({
    selectedStartYear: null,
    selectedEndYear: null,
    data: {},
    boxPlotData: []
  }),
  computed: {
    ...mapState("baseline", ["selectedStation", "color1", "color2"])
  },
  methods: {
    updatePlot() {
      let allData = [];
      Object.keys(this.data).forEach(year => {
        allData = allData.concat(this.data[year]);
      });
      this.boxPlotData[0] = {
        name: `${this.selectedStartYear}-${this.selectedEndYear}`,
        y: allData,
        type: "box",
        marker: { color: this.color2 }
      };
      plotly.react("annualComparisonPlot", this.boxPlotData, this.layout);
    },
    createBoxPlotData() {
      this.boxPlotData = [];
      let allData = [];
      Object.keys(this.data).forEach(year => {
        this.boxPlotData.push({
          name: `${year}`,
          y: this.data[year],
          type: "box",
          marker: { color: this.color1 }
        });
        allData = allData.concat(this.data[year]);
      });
      this.boxPlotData.unshift({
        name: `${this.selectedStartYear}-${this.selectedEndYear}`,
        y: allData,
        type: "box",
        marker: { color: this.color2 }
      });
      const config = this.getPLotConfigs();

      this.layout = this.getBoxLayout(this.selectedStation.rastless_unit);
      plotly.newPlot(
        "annualComparisonPlot",
        this.boxPlotData,
        this.layout,
        config
      );
    }
  },
  watch: {
    reload() {
      this.fetchStatistics(this.region, this.selectedStation.id, "yearly").then(
        results => {
          this.data = results;
          this.createBoxPlotData();
        }
      );
    },
    async selectedStation() {
      this.data = await this.fetchStatistics(
        this.region,
        this.selectedStation.id,
        "yearly"
      );
      this.selectedStartYear = this.years[0];
      this.selectedEndYear = this.years[this.years.length - 1];
      this.createBoxPlotData();
    },
    async selectedEndYear() {
      this.data = await this.fetchStatisticsWithinTimeRange(
        this.region,
        this.selectedStation.id,
        "yearly",
        `${this.selectedStartYear}-01-01`,
        `${this.selectedEndYear}-12-31`
      );
      this.updatePlot();
    },
    async selectedStartYear() {
      this.data = await this.fetchStatisticsWithinTimeRange(
        this.region,
        this.selectedStation.id,
        "yearly",
        `${this.selectedStartYear}-01-01`,
        `${this.selectedEndYear}-12-31`
      );
      this.updatePlot();
    },
    showThresholds() {
      if (this.showThresholds) {
        if (this.thresholds.local_thresholds !== null) {
          this.layout = this.addThresholdLayout(
            this.layout,
            this.thresholds.local_thresholds.threshold_alert,
            this.thresholds.local_thresholds.threshold_warning
          );
        } else {
          this.layout = this.addThresholdLayout(
            this.layout,
            this.thresholds.global_thresholds.threshold_alert,
            this.thresholds.global_thresholds.threshold_warning
          );
        }
        plotly.react("annualComparisonPlot", this.boxPlotData, this.layout);
      } else {
        this.layout = this.getBoxLayout(this.selectedStation.rastless_unit);
        plotly.react("annualComparisonPlot", this.boxPlotData, this.layout);
      }
    }
  },
  async created() {
    this.data = await this.fetchStatistics(
      this.region,
      this.selectedStation.id,
      "yearly"
    );
    this.selectedStartYear = this.years[0];
    this.selectedEndYear = this.years[this.years.length - 1];
    this.createBoxPlotData();
  }
};
</script>

<style scoped></style>
