<template>
  <v-card style="height: 282px">
    <v-card-text class="black--text pt-2">
      <v-row style="align-items: center" no-gutters>
        <v-col>
          <span class="text-h6 font-weight-regular mb-0 pb-0">
            {{ selectedStation.rastless_product.toUpperCase() }} -
            {{ $t("baseline.monthlyComparison") }}</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="px-2">
          <v-select
            style="width: 150px"
            dense
            hide-details
            :items="years"
            v-model="selectedYear1"
            label="Layer"
            solo
            return-object
          ></v-select>
        </v-col>
        <v-col cols="auto" class="px-2">
          <span> {{ $t("baseline.vs") }} </span>
        </v-col>
        <v-col cols="auto" class="pl-2">
          <v-select
            style="width: 150px"
            dense
            hide-details
            :items="years"
            v-model="selectedYear2"
            label="Layer"
            solo
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <div
      ref="plot"
      id="monthlyComparisonPlot"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '70%'
      }"
    ></div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import baselineMixin from "@/core/mixins/baseline.mixin";
import plotly from "plotly.js-dist";

export default {
  name: "MonthlyComparison",
  props: {
    showThresholds: { type: Boolean, default: () => false },
    thresholds: { type: Object },
    station: { type: Object },
    years: { type: Array },
    region: null,
    reload: { type: Boolean }
  },
  mixins: [baselineMixin],
  data: () => ({
    selectedYear1: null,
    selectedYear2: null,
    data: [],
    trace1: [],
    trace2: [],
    boxPlotData: []
  }),
  methods: {
    async createTrace(year, color) {
      const yearData = await this.fetchStatisticsWithinTimeRange(
        this.region,
        this.selectedStation.id,
        "monthly",
        `${year}-01-01`,
        `${year}-12-31`
      );
      let x = [];
      let y = [];
      this.monthsDict.forEach(async month => {
        const filtered = Object.keys(yearData).filter(key =>
          key.includes(`-${month.val}`)
        );
        if (filtered.length) {
          yearData[filtered].forEach(value => {
            x.push(`${this.$t(`${month.label}`)}`);
            y.push(value);
          });
        } else {
          x.push(`${this.$t(`${month.label}`)}`);
          y.push(undefined);
        }
      });
      return {
        x: x,
        y: y,
        name: `${year}`,
        type: "box",
        marker: { color: color }
      };
    },
    createBoxPlot() {
      const config = this.getPLotConfigs();
      this.layout = this.getBoxGroupLayout(this.selectedStation.rastless_unit);
      plotly.newPlot(
        "monthlyComparisonPlot",
        [this.trace1, this.trace2],
        this.layout,
        config
      );
    },
    updatePlot(data, layout) {
      plotly.react("monthlyComparisonPlot", data, layout);
    }
  },
  computed: {
    ...mapState("baseline", [
      "selectedStation",
      "monthsDict",
      "color1",
      "color2"
    ])
  },
  watch: {
    async reload() {
      this.trace1 = await this.createTrace(this.selectedYear1, this.color1);
      this.trace2 = await this.createTrace(this.selectedYear2, this.color2);
      this.createBoxPlot([this.trace1, this.trace2], this.layout);
    },
    async selectedStation() {
      this.selectedYear1 = this.years[0];
      this.selectedYear2 = this.years[this.years.length - 1];
      this.trace1 = await this.createTrace(this.selectedYear1, this.color1);
      this.trace2 = await this.createTrace(this.selectedYear2, this.color2);
      this.createBoxPlot([this.trace1, this.trace2], this.layout);
    },
    async selectedYear1() {
      this.trace1 = await this.createTrace(this.selectedYear1, this.color1);
      this.updatePlot([this.trace1, this.trace2], this.layout);
    },
    async selectedYear2() {
      this.trace2 = await this.createTrace(this.selectedYear2, this.color2);
      this.updatePlot([this.trace1, this.trace2], this.layout);
    },
    showThresholds() {
      if (this.showThresholds) {
        if (this.thresholds.local_thresholds !== null) {
          this.layout = this.addThresholdLayout(
            this.layout,
            this.thresholds.local_thresholds.threshold_alert,
            this.thresholds.local_thresholds.threshold_warning
          );
        } else {
          this.layout = this.addThresholdLayout(
            this.layout,
            this.thresholds.global_thresholds.threshold_alert,
            this.thresholds.global_thresholds.threshold_warning
          );
        }
        this.updatePlot([this.trace1, this.trace2], this.layout);
      } else {
        this.layout = this.getBoxGroupLayout();
        this.updatePlot([this.trace1, this.trace2], this.layout);
      }
    }
  },
  async created() {
    this.selectedYear1 = this.years[0];
    this.selectedYear2 = this.years[this.years.length - 1];
    this.trace1 = await this.createTrace(this.selectedYear1, this.color1);
    this.trace2 = await this.createTrace(this.selectedYear2, this.color2);
    this.createBoxPlot(this.trace1, this.trace2);
  }
};
</script>

<style scoped></style>
